@import url('https://fonts.googleapis.com/css?family=Open+Sans');

html, body {
	margin: 0;

	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
	color: #171717;
	background-color: #c7c7c7;
}
